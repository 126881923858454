<template>
  <div class="userTree" :class="widthFixed?'fix':''">
    <img :src="require(`../../assets/images/gs-t2.png`)" alt="" class="gs-tg">
    <transition name="slide-fade">
        <div class="treeBlock" v-show="drawer">
          <div class="tree" :class="{'simpleSearch': simpleSearch}">
          <div class="gsTitle">
            <h5>参赛队伍列表</h5>
            <img :src="require('../../assets/images/gs-n6.png')" alt="">
          </div>
          <div class="searchTitle">
            <custom-search v-if="customSearch && !fixedSearch" :visible.sync="isOnSearch" append-to-body
                @select-hold="selectChange"
                @click-batch="p => $emit('click-batch', p)"
                @click-device="(v,p) => $emit('click-device', v, p)"><span></span></custom-search>
                  <!--使用外部标题-->
            <div class="tree__tool" :class="{'elevation-1': scrollHeight > 0, 'innerTool': innerTool, 'isOnSearch': isOnSearch}">
              <!-- :style="bgStyle" -->
              <template v-if="simpleSearch">
                <div class="searchBar simpleSearch tool_simple">
                  <el-autocomplete v-model="selectQueryKey" :fetch-suggestions="fetchUserList" size="small" popper-class="v-popper-tree"
                    prefix-icon="el-icon-search"
                    :placeholder="showVehicle ? $t('commonTreeUser.searchUserOrDevice') : $t('commonTreeUser.searchUser')" @select="selectChange">
                    <template slot-scope="{item}">
                      <p class="holdname">
                        <i-icon name="icon-crosshairs-gps" class="icon-crosshairs-gps" v-if="item.dataType===3"></i-icon>
                        <i-icon name="icon-car" class="icon-car" v-else-if="item.dataType===2"></i-icon>
                        <i-icon name="icon-account" class="icon-account" v-else></i-icon>
                        {{item.value}}
                      </p>
                    </template>
                  </el-autocomplete>
                  <div class="searchBar__right">
                    <i-icon v-if="!isOnTop" v-ripple name="icon-format-vertical-align-top" title="返回根用户" @click="returnTop"></i-icon>
                    <i-icon v-if="hasParent" v-ripple name="icon-arrow-up" title="返回上级" @click="returnParent"></i-icon>
                  </div>
                </div>
              </template>
              <template v-else>
                <template v-if="!isOnSearch">
                  <div class="tool_center">
                    <div class="tool__left">
                      <i class="el-icon-back imr-0" v-if="showReturn" @click="returnHandler"></i>
                      <!-- <i-icon name="icon-home" style="font-size:16px;margin-right:2px;color: var(--color-p)"></i-icon> -->
                      <span @click="toggleExpand" class="span--normal">
                        {{title}}
                        <!-- <i v-if="showArrow" class="el-icon-arrow-down span--normal arrow"
                          :class="{'hide': arrowDown}"></i> -->
                      </span>
                    </div>
                    <div class="tool__right">
                      <span v-if="!isOnTop" v-tooltip="$t('commonTreeUser.backRoot')" ><i-icon name="icon-format-vertical-align-top" @click="returnTop"></i-icon></span>
                      <span v-if="hasParent" v-tooltip="$t('commonTreeUser.backParent')" ><i-icon  name="icon-arrow-up"  @click="returnParent"></i-icon></span>
                      <span v-if="checkbox" v-tooltip="$t('commonTreeUser.includeChild')" v-show="!containChild"><i-icon  name="icon-account-multiple-outline" @click="toggleContain"  :class="{active:containChild}" ></i-icon></span>
                      <span v-if="checkbox" v-tooltip="$t('commonTreeUser.includeChild')" v-show="containChild"><i-icon name="icon-account-multiple" @click="toggleContain"  :class="{active:containChild}" ></i-icon></span>
                      <!--切换显示数量-->
                      <span v-tooltip="$t('commonTreeUser.exchangeCountVisible')" style="font-size:18px;padding:3px 2px 1px 2px;margin-right:2px;"><i-icon name="icon-123" @click="toggleShowCount" ></i-icon></span>
                      <!-- <span v-tooltip="$t('commonTreeUser.exchange')" v-if="showExchange"><i-icon name="icon-oexchange" @click="$emit('exchange')" ></i-icon></span> -->
                      <span v-tooltip="$t('commonTreeUser.search')" v-if="!fixedSearch"><i-icon name="icon-magnify" @click="() => isOnSearch = true" ></i-icon></span>
                    </div>
                  </div>
                  
                </template>
                <transition name="el-zoom-in-center">
                  <div class="searchBar tool_simple_fixed" v-if="isOnSearch">
                    <el-autocomplete v-model="selectQueryKey" :fetch-suggestions="fetchUserList" size="small" popper-class="v-popper-tree" prefix-icon="el-icon-search"
                      :placeholder="showVehicle ? $t('commonTreeUser.searchUserOrDevice') : $t('commonTreeUser.searchUser')" @select="selectChange" :trigger-on-focus="false">
                      <template slot-scope="{item}">
                        <p class="holdname">
                          <i-icon name="icon-crosshairs-gps" class="icon-crosshairs-gps" v-if="item.dataType===3"></i-icon>
                          <i-icon name="icon-car" class="icon-car" v-else-if="item.dataType===2"></i-icon>
                          <i-icon name="icon-account" class="icon-account" v-else></i-icon>
                          {{item.value}}
                        </p>
                      </template>
                    </el-autocomplete>
                    <!-- <p class="searchBar__back" @click="() => isOnSearch = false"><i class="el-icon-back imx-0"></i></p> -->
                    <p class="searchBar__back" @click="() => isOnSearch = false"><i class="el-icon-close"></i></p>
                  </div>
                </transition>
              </template>
            </div>

            <custom-search v-if="customSearch" :visible.sync="isOnFixSearch" append-to-body :input-visible="false"
                  :offset="0" trigger="manual"
                  :search-key="treeSearchKey"
                  @select-hold="selectChange"
                  @click-batch="p => $emit('click-batch', p)"
                  @click-device="(v,p) => $emit('click-device', v, p)">
              <div class="tree_seach" @click.stop.prevent v-if="fixedSearch">
                <el-input v-model="treeSearchKey" :placeholder="$t('commonTreeUser.searchPlace')" prefix-icon="el-icon-search" @focus="isOnFixSearch=true" clearable size="small"></el-input>
              </div>
            </custom-search>

          </div>
         
          <div class="tree__content" :class="{'innerTool': innerTool, 'is-fixedsearch': fixedSearch}" ref="content">
            <el-scrollbar style="height: 100%;" ref="elScroll">
            <el-button v-if="isMounted" class="tree__contentRefresh" :class="{'loading': dataLoading}" @click="refreshData" icon="el-icon-refresh" type="text" v-ripple></el-button>
            <el-tree
              v-if="treeVisible"
              ref="tree"
              :props="props1"
              :load="fetchData"
              node-key="id"
              lazy
              empty-text=""
              :check-strictly="!containChild"
              :default-expanded-keys="defaultExpandedKeys"
              :show-checkbox="checkbox"
              :render-content="renderContent"
              :expand-on-click-node="expandOnClickNode"
              @check="checkHandler"
              @node-click="clickHandler"
              >
            </el-tree>
            <vue-content-loading v-if="loading || !treeVisible" class="content__loading" :width="250" :height="140">
              <rect x="20" y="20" rx="4" ry="4" width="15" height="15" />
              <rect x="45" y="20" rx="4" ry="4" width="185" height="15" />
              <rect x="20" y="45" rx="4" ry="4" width="15" height="15" />
              <rect x="45" y="45" rx="4" ry="4" width="185" height="15" />
              <rect x="20" y="70" rx="4" ry="4" width="15" height="15" />
              <rect x="45" y="70" rx="4" ry="4" width="185" height="15" />
            </vue-content-loading>
            </el-scrollbar>
          </div>

        </div>
      </div>
    </transition>
    <!-- <div class="collapse" @click="handlerDrawer" v-if="drawerDisable">
      <span :class="drawer?'on':''">{{$t('commonListVehicle.userList')}}<i class="el-icon-arrow-right"></i></span>
    </div> -->
    <div class="collapse" @click="handlerDrawer" v-if="drawerDisable">
      <div :class="drawer?'on':''">
        <div class="i top"></div>
        <div class="cn">
          <!-- <span :class="$i18n.locale">{{$t('commonListVehicle.userList')}}</span> -->
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="i bot"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { QueryRootPath, QueryTreePath, QueryDevicePathByObjectID } from '@/api/common'
import * as API from '@/api/tree'
import { VclCode } from 'vue-content-loading'
import VueContentLoading from 'vue-content-loading'
import {setUserTreeSetting, getUserTreeSetting} from '@/common/cache'
import {REFRESH_TREE_USER} from '@/common/bus-types'
// const CustomSearch = require('@/views/common/custom-search' + process.env.VUE_APP_VRC + '.vue').default
import CustomSearch from '@/views/common/custom-search'
// 显示用户列表的组件
export default {
  props: {
    // 是否显示单选框
    widthFixed: {
      type: Boolean,
      default: true
    },
    checkbox: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: '#FFF'
    },
    rootHoldId: {
      type: Number,
      default: null
    },
    expandOnClickNode: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    showVehicle: {
      type: Boolean,
      default: false
    },
    // 是否显示车辆数量
    showObjectCount: {
      type: Boolean,
      default: false
    },
    showReturn: {
      type: Boolean,
      default: false
    },
    showArrow: {
      type: Boolean,
      default: false
    },
    showExchange: {
      type: Boolean,
      default: false
    },
    arrowDownDefault: {
      type: Boolean,
      default: true
    },
    // 标题
    title: {
      type: String,
      default: '用户列表'
    },
    // 使用cookie
    useLocalSetting: {
      type: Boolean,
      default: true
    },
    mdtTypeID: {
      type: Number,
      default: null
    },
    // 全部可勾选
    releaseCheck: {
      type: Boolean,
      default: false
    },
    disableCheckDevice: {
      type: Boolean,
      default: false
    },
    innerTool: {
      type: Boolean,
      default: false
    },
    // 简单搜索
    simpleSearch: {
      type: Boolean,
      default: false
    },
    customSearch: {
      type: Boolean,
      default: false
    },
    fixedSearch: {
      type: Boolean,
      default: false
    },
    // drawer: {
    //   type: Boolean,
    //   default: true
    // },
    drawerDisable: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    bgStyle() {
      if (!this.bgColor) {
        return {}
      } else {
        return {
          backgroundColor: this.bgColor,
          color: this.textColor
        }
      }
    },
    isOnTop() {
      return this.rootHoldIdInner === null || this.rootItem.parentHoldId === null
    },
    hasParent() {
      return this.rootItem && this.rootItem.parentHoldId
    }
  },
  watch: {
    arrowDownDefault(val) {
      this.arrowDown = val
    },
    isOnSearch(val) {
      if (val && this.selectOptions.length===0) {
        this.fetchUserList()
      }
    },
    rootHoldId(val) {
      this.rootHoldIdInner = val
    },
    rootHoldIdInner(val, oldVal) {
      if (val !== oldVal) {
        this.treeVisible = false
        setTimeout(() => {
          this.treeVisible = true
          this.$nextTick(() => {
            this.restoreState()
          })
        }, 0)
      }
    },
    checkList(val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.$emit('check', this.checkList)
      this.saveStateToCookie()
    },
    containChild() {
      this.saveStateToCookie()
    },
    showObjectCount() {
      this.refreshData()
    },
    treeSearchKey(val) {
      if (val) {
        this.isOnFixSearch = true
      } else {
        this.isOnFixSearch = false
      }
    }
  },
  data() {
    return {
      loading: true,
      dataLoading: false,
      scrollHeight: 0,
      props1: {
        label: 'label',
        children: 'children',
        isLeaf: 'isLeaf'
      },
      rootHoldIdInner: this.rootHoldId,
      defaultExpandedKeys: [],
      rootItem: null,
      checkList: [],
      isOnSearch: false,
      isOnFixSearch: false,
      selectQueryKey: '',
      selectLoading: false,
      selectOptions: [],
      arrowDown: this.arrowDownDefault,
      treeVisible: true,  
      containChild: true,
      isMounted: false,
      treeSearchKey: '',
      drawer:true
    }
  },
  components: {
    VclCode,
    VueContentLoading,
    CustomSearch
  },
  methods: {
    handlerDrawer(){
      this.drawer = !this.drawer
      this.$emit('drawer', this.drawer)
    },
    fetchData(node, resolve) {
      if (node.level === 0) {
        this.loading = true
        return QueryRootPath(this.rootHoldIdInner, this.showVehicle, this.mdtTypeID, this.showObjectCount).then(({data}) => {
          this.rootItem = data[0]
          this.defaultExpandedKeys = data[0].children ? [data[0].id] : []
          this.loading = false
          resolve(data.slice())
        })
      }

      if(node.level === 1) {
        return resolve(this.rootItem.children)
      }

      this.dataLoading = true
      if (node.data.type === 1) {
        QueryTreePath(node.data.id, this.showVehicle, this.mdtTypeID, this.showObjectCount).then(({data}) => {
          this.dataLoading = false
          resolve(data.slice())
        }).catch(err => {
          console.error('QueryTreePath', err)
          this.dataLoading = false
        })
      } else if (node.data.type === 2) {
        QueryDevicePathByObjectID(node.data.id - 10000000, this.mdtTypeID).then(({data}) => {
          this.dataLoading = false

          if (this.disableCheckDevice) {
            const list = data.slice(0)
            list.forEach(k => k.disabled = true)
            return resolve(list)
          }

          if (this.releaseCheck) {
            const list = data.slice(0)
            list.forEach(k => k.disabled = false)
            return resolve(list)
          }

          resolve(data)
        }).catch(err => {
          console.error('QueryDevicePathByObjectID', err)
          this.dataLoading = false
        })
      } else {
        this.dataLoading = false
        resolve([])
      }


    },
    fetchUserList(queryKey, cb) {
      this.selectLoading = true
      const m = this.showVehicle ? API.QueryUserListWithObject : API.QueryUserList
      const promise = new Promise((resolve) => {
        m(queryKey).then(ret => {
          this.selectLoading = false
          resolve(ret.data)
          cb && cb(ret.data)
        })
      })
      return promise
    },
    checkHandler(data, state) {
      // 已勾选HoldID
      this.checkList = state.checkedKeys.slice()
    },
    clickHandler(data) {
      // console.log(data)
      this.$emit('click', data.id, data.label)
    },
    update() {
      this.$refs.elScroll.update()
    },
    renderContent(h, { node, data }) {
      const labelCount = this.showObjectCount
        ? (data.type === 1 ? <span class="label__count"> ({data.count})</span> : null)
        : null
      let prefixIcon =
        data.type === 1
        ? (
          node.level === 1
            ? <i-icon class="icon-tree icon-tree--user" name="icon-_icon-36"></i-icon>
            : (node.level === 2 ? <i-icon class="icon-tree icon-tree--user" name="icon-_icon-37"></i-icon> : <i-icon class="icon-tree icon-tree--user" name="icon-_icon-38"></i-icon> )
        )
        : (
          data.type === 2
          ? (data.isAlarm
            ? <i-icon class="icon-tree icon-tree--car alarm" name="icon-bell-ring"></i-icon>
            : (data.isOnline ? (data.TypeID===0? <i-icon class="icon-tree icon-tree--car icon-tree--font online" name="iconuser-car-copy"></i-icon>:
            (data.TypeID===1?<i-icon class="icon-tree icon-tree--car icon-tree--font online" name="iconuser-person-copy"></i-icon>:
            <i-icon class="icon-tree icon-tree--car icon-tree--font online" name="iconuser-airte-copy"></i-icon>)) : (data.TypeID===0?<i-icon class="icon-tree icon-tree--car icon-tree--font offline" name="iconuser-car-copy"></i-icon>:(
              data.TypeID===1?<i-icon class="icon-tree icon-tree--car icon-tree--font offline" name="iconuser-person-copy"></i-icon>:<i-icon class="icon-tree icon-tree--car icon-tree--font offline" name="iconuser-airte-copy"></i-icon>
            ))))
          : (data.isAlarm
            ? <i-icon class="icon-tree icon-tree--alarm" name="icon-bell-ring"></i-icon>
            : (data.isOnline ? <i-icon class="icon-tree icon-tree--gps online" name="icon-crosshairs-gps"></i-icon> : <i-icon class="icon-tree icon-tree--gps offline" name="icon-crosshairs-gps"></i-icon>) ) )
      prefixIcon = this.showIcon ? prefixIcon : null

      return (
        <span class="el-tree-node__label">
          {prefixIcon} {node.label}
          {labelCount}
        </span>
      )
    },
    restoreState() {
      if (!this.useLocalSetting) {
        // this.$refs.tree.setCheckedKeys(this.checkList)
        return
      }
      const state = getUserTreeSetting()

      if (state.checkList) {
        this.checkList = state.checkList.slice()
        this.containChild = state.containChild
        this.$refs.tree.setCheckedKeys(this.checkList)
      }
    },
    refreshData() {
      this.treeVisible = false
      setTimeout(() => {
        this.treeVisible = true
        this.$nextTick(() => {
          this.restoreState()
        })
      }, 0)
    },
    saveStateToCookie() {
      if (!this.useLocalSetting) {
        return
      }

      let state = getUserTreeSetting({})
      state = Object.assign(state, {
        checkList: this.checkList,
        containChild: this.containChild
      })

      // 保存状态到Cookie
      // 保存勾选信息
      setUserTreeSetting(state)
      //
    },
    clearStateToCookie() {
      let state = getUserTreeSetting({})
      delete state.checkList
      delete state.containChild
      // 清空Cookie保存状态
      setUserTreeSetting(state)
    },
    returnHandler() {
      this.$emit('return')
    },
    toggleExpand() {
      this.arrowDown = !this.arrowDown
      this.$emit('expand', this.arrowDown)
    },
    toggleContain() {
      this.containChild = !this.containChild
      if (this.containChild) {
        this.$message({
          message: this.$t('commonTreeUser.checkContainTip'),
          type: 'success'
        })
      } else {
        this.$message({
          message: this.$t('commonTreeUser.checkNotContainTip'),
          type: 'success'
        })
      }
    },
    toggleShowCount() {
      this.$emit('update:showObjectCount', !this.showObjectCount)
    },
    selectChange(val) {
      this.selectQueryKey = ''
      this.checkList.splice(0)
      this.checkList = []
      this.rootHoldIdInner = val.holdId
      this.isOnSearch = false
      this.$emit('click', val.holdId, val.value)
    },
    returnParent() {
      this.rootHoldIdInner = this.rootItem.parentHoldId
    },
    returnTop() {
      this.rootHoldIdInner = null
    },
    // 设置选中ID
    setCheckedKeys(list) {
      this.$refs.tree.setCheckedKeys(list)
    }
  },
  mounted() {
    // const resize = e => {
    //   this.scrollHeight = e.target.scrollTop
    // }
    // this.$refs.content.addEventListener('scroll', resize)
    // this.$once('hook:beforeDestroy', () => {
    //   this.$refs.content.removeEventListener('scroll', resize)
    // })
    this.restoreState()
    setTimeout(() => {
      this.isMounted = true
    }, 25)

  },
  created() {
    this.$bus.$on(REFRESH_TREE_USER, this.refreshData)
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin.scss";
@import "../../styles/variables-simple.scss";

.userTree{
  height: 100%;
  position: relative;
  z-index: 2;
  background-color: #000000;
  .gs-tg{
    position: absolute;
    top: -1px;
    right: -4px;
    z-index: 1;
  }
  .gsTitle{
    padding-top:15px;
    height: 60px;
    text-align: center;
    h5{
      font-size: 20px;
      text-align: center;
      color: #FFFFFF;
    }
  }
  .treeBlock{
    width:100%;
    min-width:240px;
    height: 100%;
    $primary-color: $--color-primary;
    border: 1px solid #126585;
    border-radius: 8px;
    overflow: hidden;
  .tree{
    height: 100%;
    background-color: #000000;
  }
  .tool_simple{
    padding: 9px 0;
    width: 215px;
    transition: all 0.3s ease-in-out;
    will-change: width;
    margin: 0 8px;
    position: relative;

    & ::v-deep .el-input__inner{
      border-radius: 20px;
      background-color: #28415A;
      border-color: #28415A;
      color:#fff;
    }
    .searchBar__right{
      color:#fff;
      position: absolute;
      right: 10px;
      top: 18px;
      z-index: 1;
      width: 45px;
      height: 36px;
      display: flex;
      justify-content: space-between;
    }
  }
  .tool_simple_fixed{
    position: relative;
    z-index:1;
    padding: 9px 15px;
     & ::v-deep .el-input__inner{
      border-radius: 20px;
    }
    .el-autocomplete{
      width:100%;
    }
    .searchBar__back{
      height: 50px;
      width: 28px;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
      color: #514c76;
      position: absolute;
      right: 25px;
      top: 1px;
      font-size: 14px;
    }
  }
  .tool_center{
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: $--color-text-regular;
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;
    // background-color: #f2f9ff;
    .tool__left{
      font-size: 16px;
      line-height: 40px;
    }
    .tool__right {
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      margin-right: -6px;

      & > span {
        line-height: 1;
      }
      .icon {
        font-size: 16px;
        padding: 4px;
        border-radius: 2px;
        box-sizing: content-box;
        &:hover {
          //background-color: mix($--color-primary, $--color-white, 80%);
          color: $--color-primary;
          cursor: pointer;
        }
        &.active {
          //color:#F4511E;
          color: $--color-primary;
        }
      }
      i {
        cursor: pointer;
      }
    }
  }
  .h-wrapper{
    padding: 9px 0;
    padding-top:0;
  }
  .tree_seach {
    padding: 0;
    .el-input {
      & ::v-deep input {
        border-radius: 20px;
        font-size: 12px;
        &:focus {
          border-bottom-color: var(--color-p);
          box-shadow: 0 1px 3px 0 rgba(44,34,243,.25);
          background-color: #28415A;
        }
      }
    }
  }

  .tree__content {
    @include scroll-bar-tree;
    height: calc(100% - 55px);

    overflow: hidden;
    position: relative;
    &.is-fixedsearch {
      height: calc(100% - 90px);
    }
    $barHeight: 17px;
    .el-scrollbar {
      height: 100%;
      & ::v-deep .el-scrollbar__wrap {
        height: calc(100% + #{$barHeight});
      }
    }

    .el-tree ::v-deep {
      background-color: #000000;
      & > .el-tree-node {
        min-width: 100%;
        display: inline-block !important;
      }
    }
    &Refresh {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      padding: 0 7px;
      line-height: 28px;
      font-size: 15px;
      border: 0;
      border-radius: 50%;
      transition: all 0.3s ease;
      &:hover {
        background-color: #323D46;
      }
      &.loading {
        animation: loading 2s linear infinite;
      }
      @keyframes loading {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    // &.innerTool {
    //   height: 100%;
    // }

    & ::v-deep .el-tree-node__label {
      font-size: 12px;
      font-weight: 500;
      color:#fff;
      .label__count {
        font-weight: normal;
        color: $--color-primary-light-2;
      }
      .icon-tree {
        font-size: 16px;
        &--user {
          font-size: 13px;
          color: #fff;
        }
        &--car {
          color: #00A1D9;
          &.alarm {
            color: #F45448;
          }
          &.online {
            color: #4CAF50;
          }
          &.offline {
            color: #BDBDBD;
          }
        }
        &--gps {
          color: #607D8B;
          &.online {
            color: #4CAF50;
          }
          &.offline {
            color: #BDBDBD;
          }
        }
        &--alarm {
          color: #F45448;
        }
        &--font{
          font-size: 14px;
        }
      }
    }

    .content__loading {
      position: absolute;
      left: 0;
      top: 28px;
    }
  }
  &:hover .tree__content{
    overflow-x: auto;
  }


  }
}

.collapse {
  width: 20px;
  position: absolute;
  right: -20px;
  top: 40%;
  margin-top: -80px;
  z-index: 1;
  cursor: pointer;
  .i{
    width:20px;
    height: 26px;
  }
  // .top{
  //   background: url(../../assets/images/collapse1.png);
  //   background-size: 100% 100%;
  // }
  // .bot{
  //   background: url(../../assets/images/collapse2.png);
  //   background-size: 100% 100%;
  // }
  .cn{
    width:20px;
    height: 70px;
    line-height: 72px;
    overflow: hidden;
    background-color: #00142F;
    border: 1px solid #126585;
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    span {
      display: none;
      overflow: hidden;
      color: #fff;
      font-size: 12px;
      padding-left: 3px;
      padding-top:4px;
    }
    .zh{
      display: block;
    }
    i {
      color: #fff;
      transition: transform 0.5s;
    }
  }
  .on {
    i {
      transform: rotate(180deg);
    }
  }
}
.fix{
  .treeBlock{
    width:240px;
  }
}
</style>

